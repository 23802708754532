import(/* webpackMode: "eager" */ "/home/newsite/web/newsite.sapient.tools/private/sapientpro-frontend/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/newsite/web/newsite.sapient.tools/private/sapientpro-frontend/src/components/CookiesPolicyBanner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/newsite/web/newsite.sapient.tools/private/sapientpro-frontend/src/components/GoogleAnalytics.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/newsite/web/newsite.sapient.tools/private/sapientpro-frontend/src/components/Hotjar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/newsite/web/newsite.sapient.tools/private/sapientpro-frontend/src/components/ReffererDetector.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/newsite/web/newsite.sapient.tools/private/sapientpro-frontend/src/store/JotaiProvider.tsx");
;
import(/* webpackMode: "eager" */ "/home/newsite/web/newsite.sapient.tools/private/sapientpro-frontend/src/styles/globals.scss");
